@use '@/styles/utils/mixins.scss' as *;

.blogArticleCardsWrapper {
  display: flex;
  flex-direction: column;
  gap: 5rem;

  .sectionHeadingContainer {
    display: flex;
    gap: 2.5rem;
    justify-content: space-between;

    @include tab() {
      flex-direction: column;
    }
  }

  .sectionDescription {
    max-width: 29rem;
  }
  .blogsContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 3rem;
  }

  .blogCardImageContainer {
    position: relative;
    flex: 0 0 auto;

    aspect-ratio: 109/97;
    overflow: hidden;
    border-radius: 1.25rem;

    @include mob-tab() {
      aspect-ratio: 1/1;
    }

    .blogCardImage {
      height: 100%;
      width: 100%;
      object-fit: cover;
      -webkit-box-shadow: 0rem 0rem 0rem 0rem rgba($neutral03, 0);
      -moz-box-shadow: 0rem 0rem 0rem 0rem rgba($neutral03, 0);
      box-shadow: 0rem 0rem 0rem 0rem rgba($neutral03, 0);
    }
  }

  .blogCardContent {
    display: flex;
    flex-direction: column;
    gap: 1.875rem;
    padding-right: 1.875rem;
    grid-column: span 2;

    @include mob-iphone6() {
      padding-right: 0;
    }
  }

  .blogCardHeader {
    display: flex;
    flex-direction: column;
    gap: 0.625rem;
    @include mob() {
      gap: 1.5rem;
    }
  }

  .blogCardTitle {
    font-size: 1.5rem;
    font-weight: 500;
    color: $dark;
    line-height: 1.1;
    @include mob() {
      font-size: 1rem;
      font-weight: bold;
    }
  }

  .blogCardMeta {
    display: flex;
    align-items: center;
    gap: 1.25rem;
    font-size: 0.875rem;
    color: $neutral07;

    @include mob-iphone6() {
      gap: 1rem;
      font-size: 0.75rem;
    }

    & > * {
      &:not(:first-child) {
        display: flex;

        gap: 1.25rem;
        @include mob-iphone6() {
          gap: 1rem;
        }
        &::before {
          content: '';
          width: 0.063rem;
          display: block;
          background-color: $neutral07;
        }
      }
    }
  }

  .blogCardDescription {
    font-weight: 400;
    color: $dark;
    line-height: 1.2;
    font-size: 1rem;
    width: 100%;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    @include mob() {
      display: none;
    }
  }

  .blogsCardsList {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-auto-rows: minmax(auto, 1fr);
    grid-column-gap: 5rem;
    grid-row-gap: 3.75rem;

    @include tab-des() {
      grid-template-columns: repeat(1, 1fr);
      grid-auto-rows: max-content;
      margin: 0 -1.875rem;
      width: calc(100% + 3.75rem);
    }

    @include mob() {
      grid-row-gap: 2rem;
    }

    .blogCard {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      padding: 1.125rem;
      gap: 1.875rem;
      border-radius: 1.25rem;
      background-color: $white;

      @include mob-iphone6() {
        gap: 1.5rem;
      }

      & * {
        transition: all 0.2s ease-in-out;
      }

      @include tab-des() {
        -webkit-box-shadow: 0rem 0.375rem 1rem 0rem rgba($black, 0.12);
        -moz-box-shadow: 0rem 0.375rem 1rem 0rem rgba($black, 0.12);
        box-shadow: 0rem 0.375rem 1rem 0rem rgba($black, 0.12);
      }

      &:hover,
      &:focus,
      &:active {
        .blogCardImageContainer {
          .blogCardImage {
            -webkit-box-shadow: 0rem 0.375rem 1rem 0rem rgba($black, 0.12);
            -moz-box-shadow: 0rem 0.375rem 1rem 0rem rgba($black, 0.12);
            box-shadow: 0rem 0.375rem 1rem 0rem rgba($black, 0.12);
          }
        }

        .blogCardTitle {
          color: $primary;
        }
      }

      &:first-child {
        grid-area: 1 / 1 / 4 / 2; // Spanning multiple rows
        display: flex;
        flex-direction: column;
        padding: 0;
        gap: 1.875rem;
        border-radius: 0rem;
        background-color: transparent;

        @include tab-des() {
          box-shadow: none;

          .blogCardContent {
            margin: 0rem 1.875rem;
            padding-bottom: 3.75rem;
            border-bottom: 0.063rem solid $primaryAlt;
          }
        }

        @include mob() {
          .blogCardContent {
            margin-bottom: 0.5rem;
          }
        }

        .blogCardImageContainer {
          width: 100%;
          aspect-ratio: 215/163;
          @include tab-des() {
            aspect-ratio: 75/38;
          }
        }

        .blogCardContent {
          padding-right: 0rem;
        }

        .blogCardHeader {
          gap: 1rem;

          @include tab-des() {
            gap: 1.875rem;
          }
        }

        .blogCardTitle {
          font-size: 2.5rem;
          @include mob() {
            font-size: 1.5rem;
          }
        }

        .blogCardDescription {
          @include mob() {
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 5;
            -webkit-box-orient: vertical;
          }
        }
      }
    }
  }
}
